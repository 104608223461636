import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import "./quickeepage.css";
import chickenntinz from "../../assets/chickenntinzblack.jpg";
import archintel from "../../assets/Archintel.jpeg";
import GetPlaystore from "../../assets/Google_Play-Icon-Logo.wine.png";
import Sales from "../../assets/1.jpg";
import Order from "../../assets/2.jpg";
import Delivery from "../../assets/3.jpg";
import QuicKee from "../../Components/Quickee.jpeg"

const QuicKeePage = () => {
  return (
    <div className="quickee-page">
      <Helmet>
        <title>QuicKee: Beyond Food Ordering</title>
        <meta name="description" content="Transforming the way students and restaurants connect through innovative food ordering solutions." />
        <meta name="keywords" content="QuicKee, food ordering, campus, students, restaurants, technology" />
        <meta property="og:title" content="QuicKee: Beyond Food Ordering" />
        <meta property="og:description" content="Efficient and seamless food ordering platform designed for students and restaurants." />
        <meta property="og:image" content={QuicKee} />
        <meta property="og:url" content="https://quickeeapp.com/about" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-bg">
          {/* Animated Content */}
          <motion.div
            className="hero-content"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: "easeOut" }}
          >
            <h1>Welcome to QuicKee: Beyond Food Ordering</h1>
            <p className="text">
              Transforming the way students and restaurants connect, one order
              at a time
            </p>
            <motion.button
              className="cta-button"
              whileHover={{
                scale: 1.05,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              }}
              whileTap={{ scale: 0.95 }}
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.quickee",
                  "_blank"
                )
              }
            >
              <img
                src={GetPlaystore}
                alt="Play Store"
                className="play-store-icon"
              />
              Download from Play Store
            </motion.button>
          </motion.div>
        </div>
      </section>

      {/* About Section */}
      <motion.section
        className="about-section"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h2>About QuicKee: Beyond Food Ordering</h2>
        <p>
          QuicKee is a Quild Technology product designed to make food ordering
          easier for university students while helping restaurants manage sales
          more effectively.
        </p>

        {/* YouTube Video: Quild Tech Overview */}
        <div className="video-iframe-container">
          <h3>Watch QuicKee: Beyond Food Ordering Overview</h3>
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/95JZz3fvSxI"
            title="Quild Technology Overview"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="iframe-video"
          ></iframe>
        </div>
      </motion.section>

      {/* Quild Technology Section */}
      <motion.section
        className="quild-tech-section"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h2>About Quild Technology</h2>
        <p>
          Quild Technology is a software development company based to provide
          high-quality products or services to our customers, showcasing
          innovation, reliability, and excellence. We aim to establish ourselves
          as a leader in the industry, delivering solutions that exceed customer
          expectations and contribute positively to society.
        </p>
      </motion.section>

      {/* Partnerships Section */}
      <motion.section
        className="partnerships-section"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h2>Our Partners</h2>
        <div className="partners-grid">
          <div className="partner-card">
            <img src={chickenntinz} alt="Chicken n Tinz" loading="lazy" className="img2"/>
            <p>Chicken n Tinz</p>
          </div>
          <div className="partner-card">
            <img src={archintel} alt="Archintel" loading="lazy" className="img2"/>
            <p>ArchIntel LTD</p>
          </div>
        </div>
      </motion.section>

      {/* Schools Onboarded Section */}
      <motion.section
        className="schools-section"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h2>Schools Onboarded</h2>
        <div className="schools-grid">
          <div className="school-card">
            <h3>JABU</h3>
          </div>
          <div className="school-card">
            <h3>Reedeemers University</h3>
          </div>
          <div className="school-card">
            <h3>Crescent University</h3>
          </div>
        </div>

        <div className="video-iframe-container">
          <h3>Reviewing QuicKee: Beyond Food Ordering</h3>
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/5WHE2Nua9Cw?si=ufmkZuPRgyLDH2uA"
            title="Our Partnership Journey"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="iframe-video"
          ></iframe>
        </div>
      </motion.section>

      {/* Problems Solved Section */}
      <motion.section
        className="problems-section"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h2>What We Aim to Solve</h2>
        <div className="problems-list">
          <div className="problem-item">
            <img src={Order} alt="Reduce queues" />
            <p>Reduce long queues during food orders</p>
          </div>
          <div className="problem-item">
            <img src={Sales} alt="Sales tracking" />
            <p>Enhance restaurant sales tracking</p>
          </div>
          <div className="problem-item">
            <img src={Delivery} alt="Time management" />
            <p>Optimize student time management</p>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default QuicKeePage;
