import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Signin, setNotifyMessage } from "../../Features/kitchenSlice";
import { notification } from "antd";
import {
  selectKitchen,
  useAppSelector,
  useAppDispatch,
} from "../../Store/store";
import { Eye } from "phosphor-react";

function SignIn() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { notifyMessage, userData } = useAppSelector(selectKitchen);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    Email: "",
    Password: "",
  });

  useEffect(() => {
    if (userData) {
      navigate("/home");
    }
  }, [userData, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (window.location.pathname === "/signIn") {
      if (notifyMessage?.isSuccess === true) {
        var response = { ...notifyMessage };
        delete response.isSuccess;
        response = { ...response };
        notification.success(response);
        dispatch(setNotifyMessage(null));
        if (
          response?.message !== "Email Verified Success" ||
          response?.message !== "Password Updated"
        ) {
          navigate("/home");
        }
      } else if (notifyMessage?.isSuccess === false && notifyMessage?.message) {
        response = { ...notifyMessage };
        delete response.isSuccess;
        response = { ...response };
        notification.error(response);
        dispatch(setNotifyMessage(null));
        if (response?.message === "Unverified email") {
          navigate(`/verifyEmail?showResend=true&email=${formData.Email}`);
        }
      }
    }
  }, [navigate, dispatch, notifyMessage, formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      Email: formData.Email,
      Password: formData.Password,
    };
    dispatch(Signin(payload));
  };

  const handleGoToSignUp = () => {
    navigate("/");
  };

  const handleForgotPassword = () => {
    navigate("/forgotPassword");
  };

  return (
    <section className="bg-gray-50 min-h-screen flex items-center justify-center">
      <div className="bg-grey-100 flex rounded-2xl shadow-lg max-w-3xl p-5 items-center">
        <div className="md:w-1/2 px-16">
          <h2 className="text-2xl text-[#c45628] font-bold">Login</h2>
          <p className="text-sm text-[#c45628] mt-2">
            If you're a restaurant already, easily log-in
          </p>

          <form
            className="flex flex-col gap-2 w-[375px]"
            onSubmit={handleSubmit}
          >
            <input
              type="email"
              id="Email"
              name="Email"
              placeholder="Email"
              value={formData.Email}
              onChange={handleInputChange}
              className="p-2 mt-8 rounded-x1 border"
              required
            />

            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="Password"
                name="Password"
                placeholder="Password"
                value={formData.Password}
                onChange={handleInputChange}
                className="p-2 rounded-x1 border"
                required
              />
              <Eye
                className="absolute top-1/2 right-0 -translate-y-3/4 right-[120px]"
                color="grey"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>

            <button
              type="submit"
              className="bg-[#c45628] rounded-x1 text-white py-2 w-[262px]"
            >
              Login
            </button>
          </form>
          <p
            className="mt-10 text-xs border-b border-grey-400 py-4"
            onClick={handleForgotPassword}
          >
            Forgot your password?
          </p>

          <div className="mt-3 text-xs flex justify-between item-center cursor-pointer">
            <p>Don't have an account?</p>
            <button
              className="py-2 px-3 bg-[#f2f2f2] border rounded-xl mt-[-7px] mb-[20px]"
              onClick={handleGoToSignUp}
            >
              Register
            </button>
          </div>
        </div>

        <div className="md:block hidden w-1/2">
          <img
            src="main.jpeg"
            alt="Login illustration"
            className="rounded-2xl shadow-lg ml-5"
          />
        </div>
      </div>
    </section>
  );
}

export default SignIn;
